import { useEffect } from "react";
import { useState } from "react";
import { GetApplicationFee, GetPaymentStatus, GetProspectivePayment, InitiateAcceptancePayment, InitiateApplicationPayment, InitiateProspectiveResultCheckingPayment, InitiateResultCheckingPayment } from "../../../api/application";
import Footer from "../../../components/Footer";
import { getCurrentUser } from "../../../components/services/auth/authService";
import moment from "moment";
import { toast } from "react-toastify";
import ApprovedPaymentComponent from "../component/ApprovedPaymentComponent";
import ApprovedResultComponent from "../component/ApprovedResultComponent";
import useProspectivePayment from "../../../hooks/useProspectivePayment";
import api from "../../../api/api";
import useProspectiveInfo from "../../../hooks/useProspectiveInfo";

const ApprovedPayment = () => {
  const user = getCurrentUser();
  const [data, setData] = useState([]);
  const [acceptanceFee, setAcceptanceFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [resultFee2, setResultFee2] = useState();
  const [secondSitting, setSecondSitting] = useState()
  const [loading, setLoading] = useState(false)
  const [btnText, setBtnText] = useState("Proceed to payment");

  const {data: paymentData, acceptanceStatus, resultStatus} = useProspectivePayment()
  const {data:info} = useProspectiveInfo()
  console.log("information",info)


  const getPaymentDetails = async () => {
    try {
      const { data } = await GetApplicationFee();
      console.log(data);
      setAcceptanceFee(data[24]);
      setResultFee(data[4])
      setResultFee2(data[5])
    } catch (error) {}
  }

  const payAcceptanceFee = async () => {
    try {
        setBtnText("Initializing...")
      setLoading(true);
      const { data } = await InitiateAcceptancePayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made")
        setLoading(false)
        setBtnText("Proceed to payment")
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };

  const payResultCheckFee = async () => {
    try {
      setLoading(true);
      const { data } = await InitiateProspectiveResultCheckingPayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made")
        setLoading(false)
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
    }
  };

  const getSecondSittingCourses = async () => {
    try {
      const response = api.get(`/admissions/applicant/olevel/${user?.admission_id}/2`)
        .then(({data}) => {
          setSecondSitting( data.olevel)
        })
      
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getPaymentDetails()
    getSecondSittingCourses()
  }, []);
  return (
    <>
      <div class="page-wrapper">
        {(paymentData) && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Payment</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Payment</h4>
                  </div>
                  {(acceptanceStatus?.payment_status !== 'success') && 
                  <ApprovedPaymentComponent
                    loading={loading}
                    btnText={btnText}
                    acceptanceFee={acceptanceFee}
                    handlePayment={payAcceptanceFee}
                    stateOfOrigin={info?.state_origin}
                  />
                  }
                    {(acceptanceStatus?.payment_status === 'success') &&
                    <div class="alert dspg-success" role="alert">
                    All payments completed.
                  </div>
                    }
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default ApprovedPayment;
