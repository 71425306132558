import { useEffect } from "react";
import { useState } from "react";
import {
  GetPaymentInvoice,
  GetPaymentStatus,
  GetProspectivePayment,
  InitiateAcceptancePayment,
  InitiateApplicationPayment,
  InitiateProspectiveResultCheckingPayment,
  InitiateResultCheckingPayment,
  VerifyApplicationPayment,
} from "../../../api/application";
import Footer from "../../../components/Footer";
import { getCurrentUser } from "../../../components/services/auth/authService";
import moment from "moment";
import { toast } from "react-toastify";
import useProspectivePayment from "../../../hooks/useProspectivePayment";
import { useNavigate } from "react-router-dom";

const ApprovedPaymentInvoice = () => {
  const user = getCurrentUser();
  const [data, setData] = useState([]);
  //   const [acceptanceFee, setAcceptanceFee] = useState();
  //   const [resultFee, setResultFee] = useState();
  const [loading, setLoading] = useState(false);
  const [applicationInvoice, setApplicationInvoice] = useState();
  const [resultInvoice, setResultInvoice] = useState();
  const navigate = useNavigate();

  const {
    acceptanceStatus: acceptanceFee,
    resultStatus: resultFee,
    courseChangeStatus: courseChangeFee,
  } = useProspectivePayment();

  const payAcceptanceFee = async () => {
    try {
      setLoading(true);
      const { data } = await InitiateAcceptancePayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
    }
  };

  const payResultCheckFee = async () => {
    try {
      setLoading(true);
      const { data } = await InitiateProspectiveResultCheckingPayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made");
        setLoading(false);
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
    }
  };
  const getInvoices = async () => {
    try {
      const { data } = await GetProspectivePayment(user.admission_id);
      setApplicationInvoice(data?.acceptance_fee);
      setResultInvoice(data?.result_checking_fee);
      console.log("Invoice", data);
    } catch (error) {}
  };

  const paymentRequery = async (ref) => {
    if (!ref) return alert("No Payment Reference Found");
    try {
      setLoading(true);
      toast.loading("Requering in Progress");
      const response = await VerifyApplicationPayment(ref);
      setLoading(false);
      window.location.reload();
    } catch (error) {
      toast.error("Verification Failed");
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, []);
  return (
    <>
      <div class="page-wrapper">
        {data && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Invoice</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Payment Invoice</h4>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="tab-content detail-list" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="general_detail">
                      <div class="row">
                        <div class="col-lg-12 col-xl-9 mx-auto">
                          <div class="card">
                            <div class="card-body">
                              {acceptanceFee && (
                                <div class="table-responsive">
                                  <table class="table mb-0">
                                    <thead class="thead-light">
                                      <tr>
                                        <th>#</th>
                                        <th>Payment</th>
                                        <th>Reference</th>
                                        <th>Generated Date</th>
                                        <th>Payment Status</th>
                                        <th>#</th>
                                        <th>#</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">1</th>
                                        <td>Acceptance Fee</td>
                                        <td>{acceptanceFee?.payment_ref}</td>
                                        <td>
                                          {moment(
                                            acceptanceFee?.generated_date
                                          ).format("DD/MM/YYYY hh:mm")}
                                        </td>
                                        <td>
                                          <span
                                            class={`badge badge-boxed  badge-soft-${
                                              acceptanceFee?.payment_status ===
                                              "success"
                                                ? "success"
                                                : "warning"
                                            }`}
                                          >
                                            {acceptanceFee?.payment_status ===
                                            "success"
                                              ? "Success"
                                              : "Pending"}
                                          </span>
                                        </td>
                                        <td>
                                          {(acceptanceFee?.payment_status === 'pending' || acceptanceFee?.payment_status === 'ongoing' || acceptanceFee?.payment_status === 'abandoned') &&
                                          acceptanceFee?.paystack_ref ? (
                                            <button
                                              className="btn btn-default"
                                              disabled={loading}
                                              onClick={() =>
                                                paymentRequery(
                                                  acceptanceFee?.paystack_ref
                                                )
                                              }
                                            >
                                              Requery
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td>
                                          {acceptanceFee?.payment_status ===
                                          "success" ? (
                                            <button
                                              className="btn dspg-danger"
                                              disabled={loading}
                                              onClick={() =>
                                                navigate("/admission/invoice", {
                                                  state: {
                                                    invoice: applicationInvoice,
                                                  },
                                                })
                                              }
                                            >
                                              Print Receipt
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default ApprovedPaymentInvoice;
